<template>
  <BaseReport
    :report="report"
    :attributes="ATTRIBUTES"
    report-class="bg-theme-5"
    resource="role"
  >
    <template #image>
      <VImage
        :name="report?.role?.name"
        class="mr-3"
        :color="report?.role?.colour"
      />
    </template>

    <template #name>
      {{ report?.role?.name }}
    </template>

    <template #subtitles>
      {{ report?.role?.details }}
    </template>

    <template #registrations>
      <VAlert
        v-if="!report?.trainings?.length"
        :text="$t('app.no_trainings_found', 2)"
      />

      <Report
        v-for="item in report?.trainings"
        :key="item?.id"
        :is-detailed="isDetailed"
        :report="item"
        report-class="bg-theme-2"
      />
    </template>
  </BaseReport>
</template>

<script>
import { useI18n } from "vue-i18n";
// Components
import BaseReport from "../BaseReport";
import Report from "../trainings/Report";
import VImage from "@/components/VImage";
import VAlert from "@/components/VAlert";

export default {
  components: {
    BaseReport,
    Report,
    VImage,
    VAlert
  },
  props: {
    report: {
      type: Object,
      default: () => ({})
    },
    isDetailed: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Constants
    const ATTRIBUTES = {
      credits_offered: t("app.credits_offered", 2),
      credits_completed: t("app.credits_completed", 2),
      hours_offered: t("app.hours_offered", 2),
      hours_completed: t("app.hours_completed", 2)
    };

    return {
      ATTRIBUTES
    };
  }
};
</script>
