<template>
  <BaseReport
    :report="report"
    :attributes="ATTRIBUTES"
    :report-class="reportClass"
    resource="training"
  >
    <template #subtitles>
      <div class="version">
        {{ $t("app.versions") }}
        {{ report?.training?.version }}
      </div>
    </template>
  </BaseReport>
</template>

<script>
import { useI18n } from "vue-i18n";
// Components
import BaseReport from "../BaseReport";

export default {
  components: {
    BaseReport
  },
  props: {
    report: {
      type: Object,
      default: () => ({})
    },
    reportClass: {
      type: String,
      default: ""
    }
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Constants
    const ATTRIBUTES = {
      duration: t("app.duration"),
      credits: t("app.credits", 2),
      num_attestations: t("app.attestations", 2),
      hours_offered: t("app.hours_offered", 2),
      credits_offered: t("app.credits_offered", 2),
      num_evaluations: t("app.evaluations", 2),
      hours_completed: t("app.hours_completed", 2),
      credits_completed: t("app.credits_completed", 2),
      num_lessons: t("app.lessons", 2),
      department: t("app.departments")
    };

    return {
      ATTRIBUTES
    };
  }
};
</script>
